<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/load-public-sections";

export default {
  name: "PrivacyPolicy",

  metaInfo: {
    description: "PrivacyPolicy",
  },

  extends: View,

  mixins: [LoadSections(["privacy-policy"])],
};
</script>
